import { NextPage } from "next";
import Link from "next/link";
import { MainLayout } from "../components/Layouts/MainLayout";
import { Center, Container } from "@mantine/core";
import { Logo } from "../components/Logo/Logo";

interface Props {
  user?: any;
}

const NotFoundPage: NextPage<Props> = ({ user }) => {
  return (
    <MainLayout user={undefined} hideMyAccount={true}>
      <Container size="lg" px="lg">
        <Logo />
        <Center>
          <h1>404 - Page Not Found</h1>
        </Center>
        <Center>
          <Link href="/">
            <a>Go back home</a>
          </Link>
        </Center>
      </Container>
    </MainLayout>
  );
};

export default NotFoundPage;
